import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>Coming Soon...
        </p>
        <h1>ANSWERS FROM GOD</h1>
        <p>(or his intern...)</p>
      </header>
    </div>
  );
}

export default App;
